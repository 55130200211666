<template>
    <v-container fluid ref="myContainer">
        <v-icon style="margin: 0px" v-ripple="false" @click="openFilter()">mdi-filter</v-icon>
        <v-layout justify-center align-center>
            <DxPivotGrid :allow-sorting-by-summary="true" :allow-sorting="true" :allow-filtering="true"
                :show-row-grand-totals="false" :allow-expand-all="true"
                :height="(this.$vuetify.breakpoint.height - 164)" :show-borders="true" :data-source="dataSource"
                row-header-layout="tree" @exporting="onExporting" @cell-prepared="(e) => updateIcon(e)"
                @cell-click="(e) => onCellClick(e)" @initialized="(e) => initializedPivotGrid(e)">
                <DxFieldChooser :enabled="false" :allow-search="true" />
                <DxExport :enabled="true" />
                <DxFieldPanel :visible="true" :show-filter-fields="false" />
                <DxHeaderFilter :allow-search="true" :width="this.$vuetify.breakpoint.mobile ? 200 : 500" />
            </DxPivotGrid>
        </v-layout>
        <ModalCrud :opened="modals.filter.open" title="Filtrar" v-model="modals.filter.open"
            :loading="$store.state.loading" v-if="modals.filter.open">
            <template v-slot:body>
                <v-form ref="form" v-model="modals.filter.data.isValid" lazy-validation>
                    <v-row cols="12" no-gutters>
                        <v-col md="12">
                            <v-autocomplete class="mt-5" outlined auto-select-first v-model="modals.filter.data.idDre"
                                :items="modals.filter.dataset.dres" tabindex="1" item-value="id" item-text="descricao"
                                clearable label="DRE" />
                        </v-col>
                    </v-row>
                    <v-row cols="12" no-gutters>
                        <v-col md="12">
                            <v-autocomplete outlined auto-select-first multiple v-model="modals.filter.data.idEmpresas"
                                @keydown.native.shift="$event => selectOnShift($event, modals.filter.data.idEmpresas, modals.filter.dataset.empresas, 'id')"
                                :items="modals.filter.dataset.empresas" tabindex="2" item-value="id"
                                item-text="descricao" clearable label="Empresas" />
                        </v-col>
                    </v-row>
                    <v-row cols="12" no-gutters>
                        <v-col md="12">
                            <v-autocomplete outlined auto-select-first v-model="modals.filter.data.ano"
                                :items="modals.filter.dataset.anos" tabindex="3" clearable label="Ano" />
                        </v-col>
                    </v-row>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-card-actions>
                    <v-btn depressed color="success" @click="loadData(false)" class="button-width-large">
                        <v-icon left>mdi-content-save-plus</v-icon>
                        Confirmar
                    </v-btn>
                    <v-btn depressed color="error" @click="closeFilter(false)" class="button-width-large">
                        <v-icon left>mdi-cancel</v-icon>
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </template>
        </ModalCrud>
        <ModalCrud :opened="modals.saveFilters.open" title="Salvar Filtro" v-model="modals.saveFilters.open"
            :loading="$store.state.loading" v-if="modals.saveFilters.open">
            <template v-slot:body>
                <div class="my-5">
                    <h3>Deseja salvar os filtros para a próxima execução?</h3>
                </div>
            </template>
            <template v-slot:actions>
                <v-card-actions>
                    <v-btn depressed color="success" @click="saveFilter()" class="button-width-large">
                        <v-icon left>mdi-content-save-plus</v-icon>
                        Confirmar
                    </v-btn>
                    <v-btn depressed color="error" @click="() => modals.saveFilters.open = !modals.saveFilters.open"
                        class="button-width-large">
                        <v-icon left>mdi-cancel</v-icon>
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </template>
        </ModalCrud>
    </v-container>
</template>

<script>
import Vue, { defineComponent } from 'vue';
import { DxPivotGrid } from 'devextreme-vue';
import DxThemes from 'devextreme/ui/themes';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import ptMessages from "../../../../assets/translation/pt.json";
import { locale, loadMessages } from "devextreme/localization";
import { Workbook } from 'exceljs';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { DxExport, DxFieldChooser, DxFieldPanel, DxHeaderFilter } from 'devextreme-vue/pivot-grid';
import ModalCrud from '@/components/ModalCrud.vue';
import selectOnShift from '@/utils/selectOnShift';
import api from '@/api/api';
export default defineComponent({
    name: 'TabDRE',
    data() {
        return {
            fields: [
                {
                    caption: 'Conta',
                    width: this.$vuetify.breakpoint.mobile ? 150 : 400,
                    dataField: 'descricaoConta',
                    area: 'row',
                    allowSorting: false,
                    sortingMethod: (a, b, children) => {
                        return null
                    }
                },
                {
                    caption: 'Centro de Custo',
                    dataField: 'custoAnalitico',
                    width: 150,
                    area: 'row'
                },
                {
                    caption: 'Lançamento',
                    dataField: 'descricaoLancamento',
                    width: 150,
                    area: 'row'
                },
                {
                    caption: 'Data',
                    dataField: 'dataLancamento',
                    dataType: 'date',
                    area: 'column',
                    groupInterval: 'month'
                },
                {
                    caption: 'Valor',
                    dataField: 'valor',
                    dataType: 'number',
                    summaryType: 'sum',
                    format: {
                        currency: 'BRL',
                        precision: 2,
                        type: 'currency'
                    },
                    area: 'data',
                }
            ],
            dataset: [],
            dataSource: [],
            pivotGridInstance: null,
            containerHeight: 0,
            modals: {
                filter: {
                    open: false,
                    dataset: {
                        dres: [],
                        empresas: [],
                        anos: []
                    },
                    data: {
                        isValid: false,
                        idDre: null,
                        idEmpresas: [],
                        ano: null
                    }
                },
                saveFilters: {
                    open: false
                }
            }
        }
    },
    components: {
        DxPivotGrid,
        DxExport,
        DxFieldChooser,
        DxHeaderFilter,
        DxFieldPanel,
        ModalCrud
    },
    methods: {
        generateYears() {
            const startYear = 2008;
            const currentYear = new Date().getFullYear();
            const years = [];
            for (let x = startYear; x <= currentYear; x++) {
                years.unshift(x)
            };
            this.$data.modals.filter.dataset.anos = years;
        },
        renderIcon(iconType, id) {
            switch (iconType) {
                case '+':
                    return this.$createElement('v-icon', {
                        class: 'mdi mdi-plus',
                        style: {
                            color: '#0cab14',
                            fontSize: '18px'
                        }
                    });
                case '-':
                    return this.$createElement('v-icon', {
                        class: 'mdi mdi-minus',
                        style: {
                            color: '#F00000',
                            fontSize: '18px'
                        },
                    });
                case '=':
                    return this.$createElement('v-icon', {
                        class: 'mdi mdi-equal',
                        style: {
                            color: '#2196f3',
                            fontSize: '18px'
                        }
                    });;
                default:
                    return;
            }
        },
        onCellClick: function (e) {
            if (e.cell?.text?.includes('=')) {
                e.cancel = true;
            }
        },
        updateIcon(e) {
            if (e.area === 'row') {
                if (e.cell.type === 'D' & e.cell?.path?.length === 1) {
                    let iconComponent = null;
                    if (e.cell?.text?.includes('-')) {
                        iconComponent = this.renderIcon('-')
                    }
                    if (e.cell?.text?.includes('+')) {
                        iconComponent = this.renderIcon('+')
                    }
                    if (e.cell?.text?.includes('=')) {
                        iconComponent = this.renderIcon('=')
                    }
                    if (iconComponent) {
                        const vm = new Vue({
                            render: h => iconComponent
                        }).$mount();
                        e.cellElement.prepend(e.cellElement.firstChild, vm.$el);
                        const textNode = e.cellElement.querySelectorAll('span')[1];
                        if (textNode) {
                            textNode.textContent = e.cell.text.substring(1).trim();
                        }
                    }
                };
                if (e.cell.type === 'T' & e.cell?.path?.length === 1) {
                    let iconComponent = null;
                    if (e.cell?.text?.includes('-')) {
                        iconComponent = this.renderIcon('-')
                    }
                    if (e.cell?.text?.includes('+')) {
                        iconComponent = this.renderIcon('+')
                    }
                    if (e.cell?.text?.includes('=')) {
                        iconComponent = this.renderIcon('=')
                    }
                    if (iconComponent) {
                        const vm = new Vue({
                            render: h => iconComponent
                        }).$mount();
                        e.cellElement.prepend(e.cellElement.firstChild, vm.$el);
                        const textNode = e.cellElement.querySelectorAll('span')[1];
                        if (textNode) {
                            textNode.textContent = e.cell.text.substring(1).trim();
                        }
                    }
                }
            }
            if (e.area === "row" && e.cell && e.cell?.text?.includes('=')) {
                const expandIconContainer = e.cellElement.querySelector(".dx-expand-icon-container");
                if (expandIconContainer) {
                    expandIconContainer.innerHTML = '';
                };
            };
        },
        async onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('DRE');
            exportPivotGrid({
                component: e.component,
                worksheet: worksheet,
                customizeCell: function (options) {
                    const excelCell = options;
                    excelCell.font = { name: 'Arial', size: 12 };
                    excelCell.alignment = { horizontal: 'left' };
                }
            }).then(function () {
                worksheet.autoFilter = {
                    from: {
                        row: 1,
                        column: 1
                    },
                    to: {
                        row: worksheet.lastRow?.number,
                        column: worksheet.lastColumn?.number
                    }
                }
                workbook.xlsx.writeBuffer()
                    .then(function (buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Demonstrativo de Resultado do Exercício - CSNOTE.xlsx');
                    });
            });
            e.cancel = true;
        },
        initializedPivotGrid(e) {
            this.$data.pivotGridInstance = e.component;
            setTimeout(() => {
                e.component.repaint()
            }, 500)
        },
        async openFilter() {
            try {
                this.$store.state.loading = true;
                const { data } = await api.get('/dash/dre');
                this.$data.modals.filter.dataset = {
                    ...this.$data.modals.filter.dataset,
                    ...data
                };
                this.$data.modals.filter.open = true;
            } catch (error) {
                this.$toast.error('Falha ao carregar os dados!');
            } finally {
                this.$store.state.loading = false;
            }
        },
        closeFilter(closeWithLoad) {
            this.$data.modals.filter.dataset = {
                ...this.$data.modals.filter.dataset,
                empresas: [],
                dres: []
            };
            if (closeWithLoad) {
                this.$data.modals.filter.open = false;
                this.$data.modals.saveFilters.open = true;
                return;
            };
            this.$data.modals.filter.open = false;
        },
        async saveFilter() {
            try {
                this.$store.state.loading = true;
                await api.put('/dash/dre', this.$data.modals.filter.data);
                this.$data.modals.saveFilters.open = false;
            } catch (error) {
                this.$toast.error('Falha ao carregar os dados!');
            } finally {
                this.$store.state.loading = false;
            }
        },
        async loadData(firstLoad) {
            try {
                this.$store.state.loading = true;

                if (firstLoad) {
                    const { data } = await api.post('/dash/dre', {
                        ...this.$data.modals.filter.data,
                        firstLoad: true
                    });

                    if (!data.haveFilter) {
                        await this.openFilter();
                        this.$toast.warning('Informe os parâmetros de apuração.')
                    } else {
                        this.dataSource = new PivotGridDataSource({
                            fields: this.$data.fields,
                            store: data.dataset
                        });
                        this.$data.modals.filter.data = {
                            ...data.filters
                        }
                    };
                } else {
                    const { data } = await api.post('/dash/dre', {
                        ...this.$data.modals.filter.data,
                        firstLoad: false
                    });
                    this.dataSource = new PivotGridDataSource({
                        fields: this.$data.fields,
                        store: data.dataset
                    });
                    this.$data.modals.filter.data = {
                        ...data.filters
                    }
                    this.closeFilter(true);
                }
            } catch (error) {
                this.$toast.error('Falha ao carregar os dados!');
            } finally {
                this.$store.state.loading = false;
            }
        }
    },
    watch: {
        '$vuetify.theme.dark': function (newValue) {
            if (newValue) {
                DxThemes.current('isDark');
            } else {
                DxThemes.current('isLight');
            }
        }
    },
    async mounted() {
        await this.loadData(true);
    },
    created() {
        if (this.$vuetify.theme.dark) {
            DxThemes.current('isDark');
        } else {
            DxThemes.current('isLight');
        };
        this.generateYears();
    },
    setup() {
        loadMessages(ptMessages);
        locale(navigator.language);
        return {
            selectOnShift
        }
    }
})
</script>